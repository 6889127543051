import React, { useEffect, useContext } from "react";
import Header from "./header";
import Footer from "./footer";
import { Context } from "../redux/store";

import "../scss/styles.scss";

// const throttle = require("throttleit");

function Layout({ pageClass, children }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    //Initialization
    if (window.scrollY >= 50) {
      dispatch({
        type: "SET_HEADER_BG",
        payload: true,
      });
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY >= 50) {
        dispatch({
          type: "SET_HEADER_BG",
          payload: true,
        });
      } else {
        dispatch({
          type: "SET_HEADER_BG",
          payload: false,
        });
      }
    });
    // const listenScrollEvent = (event) => {
    //   console.log(document.body.scrollTop);
    //   if (window.scrollY >= 50) {
    //     dispatch({
    //       type: "SET_HEADER_BG",
    //       payload: true,
    //     });
    //   } else {
    //     dispatch({
    //       type: "SET_HEADER_BG",
    //       payload: false,
    //     });
    //   }
    // };
    // const throttledEvent = throttle(listenScrollEvent, 100);
    // document.body.addEventListener("scroll", throttledEvent);
    // return () => document.body.removeEventListener("scroll", throttledEvent);
  }, []);

  return (
    <div className="site-wrapper">
      <Header />
      <div className="body-container">
        <div className={`body-content ${pageClass || ""}`}>{children}</div>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
