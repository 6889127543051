import React from "react";
import { Link } from "react-scroll";

import useDeviceSize from "../hooks/use-device-size";

function Nav() {
  const handleClick = () => {
    const hamBtn = document.querySelector(".hamburger");
    hamBtn.click();
  };

  const deviceSize = useDeviceSize();

  return (
    <div className="navbar-nav">
      <nav>
        <ul>
          <li className="navbar-item">
            <Link
              to="science"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              SCIENCE
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="pipeline"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              PIPELINE
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="partners"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              PARTNERS
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="team"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              TEAM
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="news"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              NEWS
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="join-us"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              JOIN US
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              to="contact"
              className="navbar-link"
              onClick={deviceSize.mdDown && handleClick}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Nav;
