import React from "react";

function FluidContent({ className, children }) {
  return (
    <section className={`container-fluid ${className || ""}`}>
      {children}
    </section>
  );
}

export default FluidContent;
