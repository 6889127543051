import React, { useContext } from "react";
import { Link } from "gatsby";
import { Context } from "../redux/store";
import useDeviceSize from "../hooks/use-device-size";
import Nav from "./nav";
import Hamburger from "./hamburger";
import FluidContent from "./fluid-content";
import { Container } from "react-grid-system";

import paragonLogo from "../images/paragon-logo.svg";
import paragonLogoWhite from "../images/paragon-logo-white.png";

function Header() {
  const { state, dispatch } = useContext(Context);
  const deviceSize = useDeviceSize();

  return (
    <FluidContent
      className={`header-container ${state.headerBg ? "sticky" : ""}`}
    >
      <header className="site-header">
        <nav className="navbar">
          <Container className="navbar-container">
            <Link to="/" className="navbar-logo">
              {state.hamburgerOpen || state.headerBg ? (
                <img src={paragonLogoWhite} alt="Paragon logo" />
              ) : (
                <img src={paragonLogo} alt="Paragon logo" />
              )}
            </Link>
            {deviceSize.mdDown && <Hamburger />}
            <div
              className={`navbar-collapse ${
                deviceSize.smDown ? "is-mobile" : ""
              }`}
            >
              <Nav />
            </div>
          </Container>
        </nav>
      </header>
    </FluidContent>
  );
}

export default Header;
